import _ from 'lodash';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { FaUser } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';

import { useDateUpdate } from 'src/utils/helper';

import { Datum3 } from 'src/modules/sections/section-1';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const Image = dynamic(import('src/components/image'));
const WidgetPlayBtn = dynamic(import('src/components/widgets/widget-play-btn'));

type Props = {
  data: Datum3;
  showTitle: boolean;
  showBlurb: boolean;
  showByline: boolean;
  showTime: boolean;
  showCountview: boolean;
  external: boolean;
  eventDataLayer: any;
};

const CardHLG = ({ data, showTitle = true, showBlurb = true, showByline = true, showTime = true, showCountview = true, external, eventDataLayer = {} }: Props) => {
  if (_.isEmpty(data)) return null;
  return (
    <>
      <CardWrapper className='card-h-lg'>
        <a
          href={data?.link}
          tabIndex={-1}
          // title={data?.title}
          //! DATALAYER
          onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
        >
          <div className='row row-padding'>
            <div className='img-scale-animate col-12 col-md-8'>
              <Image image={data?.image} title={data?.title} external={external} />
              {data?.is_video && <WidgetPlayBtn />}
            </div>
            <div className='card --content col-12 col-md-4'>
              {showTitle && <h3 className='title text-excerpt-3'>{data?.title}</h3>}
              {showBlurb && <p className='blurb text-excerpt-4'>{data?.blurb}</p>}
              <div className='meta-contents'>
                {showByline && !_.isEmpty(data?.byline) && (
                  <div className='byline'>
                    <FaUser /> <span>{data?.byline}</span>
                  </div>
                )}
                <div className='meta'>
                  {showTime && (
                    <p className='date-time'>
                      <BiCalendar /> <span> {useDateUpdate(data?.published_at, false)} </span>
                    </p>
                  )}
                  {showCountview && (
                    <>
                      <p className='pageviews'>
                        <AiOutlineEye />
                        <span>{data.pageviews}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </a>
      </CardWrapper>
    </>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  .play-btn-video {
    bottom: 10px;
    right: 20px;
  }
  &.card-h-lg {
    margin-top: 15px;
    .row-padding {
      padding-bottom: 1.5rem;
    }
  }
  &:hover {
    h3,
    p {
      color: #ed1a3b !important;
    }
  }
  .img-scale-animate {
    position: relative;
  }
  .card {
    h3,
    p {
      transition: all 0.4s ease-in-out;
      color: black;
    }
    &.--content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 100%;
      @media (max-width: 480px) {
        margin-top: 20px;
      }
      .title {
        font-size: 28px;
        font-weight: 700;
        color: #fff;
      }
      .blurb {
        color: #ffffffa1;
        font-size: 16px;
        font-weight: 400px;
        padding-top: 15px;
      }
      h3 {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  .meta-contents {
    margin-top: 5px;
    .byline {
      color: #808080;
      font-size: 12px;
    }
    .meta {
      margin-top: 5px;
      display: flex;
      .date-time {
        font-size: 12px;
        color: #808080;
      }
      .pageviews {
        margin-left: 10px;
        font-size: 12px;
        color: #808080;
        svg {
          vertical-align: middle;
        }
        span {
          padding-left: 5px;
        }
      }
    }
  }
`;

export default CardHLG;
